* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'main';
}

@font-face {
  font-family: 'main';
  src: url('./fonts/px.ttf');
}

body {
  background: radial-gradient(blue, white 70%);
  line-height: 20px;
}

body.inverted {
  background: radial-gradient(white, blue 95%);
}

.label {
  background: rgb(0, 245, 0);
}

.inverted .label {
  background: rgb(230, 230, 230);
}

@media all and (max-width: 700px) {
  div,
  .label {
    font-size: 14px;
    line-height: 18px;
  }

  .left {
    left: 20;
  }

  .right {
    right: 20;
  }

  img {
    width: 50px;
  }
}

main {
  cursor: grab;
}

main:active {
  cursor: grabbing;
}

input[type='range'] {
  -webkit-appearance: none;
  height: 1.5px;
  cursor: pointer;
  background: black;
  border-radius: 5px;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 12px;
  height: 12px;
  background: lightgrey;
  border-radius: 50%;
  border: solid 1px black;
}

#loader {
  background: conic-gradient(transparent, black);
  animation: rotate 2s infinite linear;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
